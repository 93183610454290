// const { active } = require("browser-sync");

filterSelection("all")
function filterSelection(c) {
    var x, i;
    x = document.getElementsByClassName("filterDiv");
    if (c == "all") c = "";
    for (i = 0; i < x.length; i++) {
        w3RemoveClass(x[i], "show");
        if (x[i].className.indexOf(c) > -1) tabAddClass(x[i], "show");
    }
}

function tabAddClass(element, name) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
        if (arr1.indexOf(arr2[i]) == -1) {element.className += " " + arr2[i];}
    }
}

function w3RemoveClass(element, name) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
        while (arr1.indexOf(arr2[i]) > -1) {
        arr1.splice(arr1.indexOf(arr2[i]), 1);     
        }
    }
    element.className = arr1.join(" ");
}


$(document).ready(function() {
    /*=====================SET: ADD REMOVE ACTIVE CLASS IN FILTER BUTTON=====================*/
    $('#myBtnContainer li button').on('click', function () {
        $(this).addClass('active');
        $(this).parents('li').siblings('li').find('button').removeClass('active');
    });
    /*=====================END: ADD REMOVE ACTIVE CLASS IN FILTER BUTTON =====================*/
    /*=====================SET: HEMBURGER HEADER=====================*/
	$("#nav-toggle").click(function(e){
		e.preventDefault();
		$(this).toggleClass("current_page_item");
		$("header nav").stop(true,true).toggleClass('showMobMenu');
	});

    $(window).scroll(function() {    
		var scroll = $(window).scrollTop();
		if (scroll >= 1) {
			$("header nav").removeClass("showMobMenu");
			$("#nav-toggle").removeClass("current_page_item");
		}
	});
    /*=====================END: HEMBURGER HEADER=====================*/
	/*===============================SET: BG IMAGE JQUERY===============================*/
    $('.backBg').each(function(){
        var backbgPath = $(this).attr('src'); 
        $(this).parent('.backBgBox').css('background-image', 'url(' + backbgPath + ')');
    });
    /*===============================END: BG IMAGE JQUERY===============================*/
	/*===============================SET: JQuery 100vh height(for iphone 5s)===============================*/
	$('.banner_img').height(window.innerHeight + 'px');
	/*===============================END: JQuery 100vh height(for iphone 5s)===============================*/
    /*=====================SET: STICKY HEADER=====================*/
    $(window).scroll(function() {
        if ($(this).scrollTop() > 1){ 
            $('header').addClass("sticky");
        }
        else{
            $('header').removeClass("sticky");
        }
    });
    /*=====================END: STICKY HEADER=====================*/
    
    /*=====SET:HP ABOUT PARALLAX EFFECT=====*/
    if ($('.abt_wrap').hasClass('parallax')) {
        $('.abt_wrap.parallax').data('type', 'background');
        $('.abt_wrap.parallax').data('speed', '10');
    }
    $window = $(window);

    $('.abt_wrap.parallax').each(function() {
        var $bgobj = $(this);

        $(window).scroll(function() {
            var yPos = -(($(window).scrollTop() - $bgobj.offset().top) / $bgobj.data('speed'));

            var coords = '50% ' + yPos + 'px';

            $bgobj.css({ backgroundPosition: coords });
        });
    });
    //=====END:HP ABOUT PARALLAX EFFECT=====//
    
    // init Isotope
    var $grid = $('.grid').isotope({
      itemSelector: '.element-item',
      layoutMode: 'fitRows'
    });
    // filter functions
    var filterFns = {
      // show if number is greater than 50
      numberGreaterThan50: function() {
        var number = $(this).find('.number').text();
        return parseInt( number, 10 ) > 50;
      },
      // show if name ends with -ium
      ium: function() {
        var name = $(this).find('.name').text();
        return name.match( /ium$/ );
      }
    };
    // bind filter button click
    $('.filters-button-group').on( 'click', 'button', function() {
      var filterValue = $( this ).attr('data-filter');
      // use filterFn if matches value
      filterValue = filterFns[ filterValue ] || filterValue;
      $grid.isotope({ filter: filterValue });
    });
    // change is-checked class on buttons
    $('.button-group').each( function( i, buttonGroup ) {
      var $buttonGroup = $( buttonGroup );
      $buttonGroup.on( 'click', 'button', function() {
        $buttonGroup.find('.is-checked').removeClass('is-checked');
        $( this ).addClass('is-checked');
      });
    });
    
});